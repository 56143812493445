// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://dadyin-dev.azurewebsites.net/',
  calcUrl: 'https://dev.dadyin.com/calc/',
  authApiUrl: 'https://dadyin-dev-auth.azurewebsites.net/',
  uiURL: 'https://dev.dadyin.com/',
  stripePK: 'pk_test_51Mle3EKVGM9t2hrt7RjJMt9oUO3BNEhGMb0X8J2b6epkWd4lVjZySvJ8DzUrjjfOdEHJEPLZlbpR4DQD8zm1JdkS00IDIO6tUC',
  imgUrl: 'https://dadyinstorage.blob.core.windows.net/dev/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
